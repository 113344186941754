const mapData = [
  {
    city: "Amsterdam",
    country: "Netherlands",
    image: "/images/amsterdam.jpg",
    comments: null,
    latitude: 52.370216,
    longitude: 4.895168,
  },
  {
    city: "Barcelona",
    country: "Spain",
    image: "/images/la_segrada_barcelona.jpg",
    comments: "La Segrada Familia Church",
    latitude: 41.3851,
    longitude: 2.1734,
  },
  {
    city: "Bath",
    country: "United Kingdom",
    image: "/images/bath.jpg",
    comments: null,
    latitude: 51.3811,
    longitude: -2.359,
  },
  {
    city: "Berlin",
    country: "Germany",
    image: "/images/berlin_map.jpg",
    comments: "absolutely scandalized at the art of the Berlin Wall",
    latitude: 52.52,
    longitude: 13.405,
  },
  {
    city: "Burano",
    country: "Italy",
    image: "/images/burano.jpg",
    comments:
      "Actually an island off Venice called Burano - known for incredible lace",
    latitude: 45.4408,
    longitude: 12.3155,
  },
  {
    city: "Edfu",
    country: "Egypt",
    image: "/images/egypt.jpg",
    comments: "Egyptains love a good historical mural",
    latitude: 24.9792,
    longitude: 32.8758,
  },
  {
    city: "Cordoba",
    country: "Spain",
    image: "/images/mesquita_spain.jpg",
    comments: "The Mosque-Cathedral of Cordoba, also known as The Mezquita",
    latitude: 37.8882,
    longitude: -4.7794,
  },
  {
    city: "The Dead Sea",
    country: "Israel",
    image: "/images/israel.jpg",
    comments: null,
    latitude: 31.559,
    longitude: 35.4732,
  },
  {
    city: "Ehlanzeni",
    country: "South Africa",
    image: "/images/southafrica.jpeg",
    comments: "Aka Bourke's Luck Potholes",
    latitude: -25.3946,
    longitude: 31.2626,
  },
  {
    city: "Gedi",
    country: "Kenya",
    image: "/images/gedi.jpg",
    comments:
      "Ruins of an ancient mosque that is carbon dated to be around 1000 years old!",
    latitude: -3.3064,
    longitude: 40.0114,
  },
  {
    city: "Granada",
    country: "Spain",
    image: "/images/granada.jpg",
    comments: "The Alhambra!!!! incredible to see in person",
    latitude: 37.1773,
    longitude: -3.5986,
  },
  {
    city: "Jerusalem",
    country: "Israel",
    image: "/images/israel2.jpg",
    comments: null,
    latitude: 31.7683,
    longitude: 35.2137,
  },
  {
    city: "Kilimanjaro",
    country: "Tanzania",
    image: "/images/tanzania.jpg",
    comments: "This was... a hike",
    latitude: -3.0674,
    longitude: 37.3556,
  },
  {
    city: "Knossos",
    country: "Greece",
    image: "/images/greece.jpg",
    comments: "Serving looks and water",
    latitude: 35.2985,
    longitude: 25.1596,
  },
  {
    city: "Krakow",
    country: "Poland",
    image: "/images/poland.jpg",
    comments: "The historic Jewish ghetto in Krakow",
    latitude: 50.0647,
    longitude: 19.945,
  },
  {
    city: "Lisbon",
    country: "Portugal",
    image: "/images/lisbon.jpg",
    comments: "old train car",
    latitude: 38.7223,
    longitude: 9.1393,
  },
  {
    city: "Lake Baikal, Siberia",
    country: "Russia",
    image: "/images/siberia.jpg",
    comments: "WAAAAY hotter here than you think",
    latitude: 51.85,
    longitude: 104.866667,
  },
  {
    city: "London",
    country: "United Kingdom",
    image: "/images/london.jpg",
    comments: "A tea store for the experienced",
    latitude: 51.5074,
    longitude: 0.1278,
  },
  {
    city: "Munich",
    country: "Germany",
    image: "/images/munich.jpg",
    comments: "The overhead view of the Christmas Market",
    latitude: 48.1351,
    longitude: 11.582,
  },
  {
    city: "Paris",
    country: "France",
    image: "/images/notre_dame.jpg",
    comments: "The Notre Dame Cathedral stained glass window - shiny",
    latitude: 48.8566,
    longitude: 2.3522,
  },
  {
    city: "Petra",
    country: "Jordan",
    image: "/images/jordan.jpg",
    comments: null,
    latitude: 30.3285,
    longitude: 35.4444,
  },
  {
    city: "Prague",
    country: "Czech Republic",
    image: "/images/prague.jpg",
    comments: "Statue of Franz Kafka. Ever heard of him?",
    latitude: 50.0755,
    longitude: 14.4378,
  },
  {
    city: "Rabat",
    country: "Morocco",
    image: "/images/morocco.jpg",
    comments: null,
    latitude: 33.9716,
    longitude: 6.8498,
  },
  {
    city: "Rome",
    country: "Italy",
    image: "/images/colloseum_italy.jpg",
    comments: "Colloseum? more like Call-Them-As-I-See-Them",
    latitude: 41.9028,
    longitude: 12.4964,
  },
  {
    city: "St. Petersburg",
    country: "Russia",
    image: "/images/stpetersburg.jpg",
    comments: null,
    latitude: 59.9343,
    longitude: 30.3351,
  },
  {
    city: "Teotihuacan",
    country: "Mexico",
    image: "/images/mexico_city.jpeg",
    comments: "Ready to be sacrificed",
    latitude: 19.4326,
    longitude: -99.1332,
  },
  {
    city: "Macchu Pichu",
    country: "Peru",
    image: "/images/peru.JPG",
    comments: "5 day hike to not see one of the wonders of the world!",
    latitude: -13.163068,
    longitude: -72.545,
  },
  {
    city: "Cartagena",
    country: "Colombia",
    image: "/images/colombia.JPG",
    comments:
      "Home to Gabriel Garcia Marques and the freshest fruit in the world",
    latitude: 10.3932,
    longitude: -75.4832,
  },
];

export default mapData;
